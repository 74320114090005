<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :hasChanges="hasChanges"
    :customClass="'create-costing create-form'"
    v-if="getPermission('costing:create') || getPermission('costing:update')"
  >
    <template v-slot:header-title>
      <div class="d-flex justify-space-between">
        <h1 class="form-title text-truncate d-flex align-baseline">
          Costing for
          <div
            v-if="vCustomerId"
            class="ml-3 form-title-link cursor-pointer text-h5"
            v-on:click="openDialog('customer')"
          >
            {{ vCustomer.company_name }}
          </div>
          <template v-else>
            <div
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openDialog('customer')"
            >
              Select a Company
              <v-icon large color="cyan">mdi-domain</v-icon>
            </div>
            <div class="mx-3">OR</div>
            <div
              class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
              v-on:click="createCustomerDialog()"
            >
              Create a new Company
              <v-icon large color="cyan darken-4"
                >mdi-plus-circle-outline</v-icon
              >
            </div>
          </template>
        </h1>
        <!--     <div class="text-h4 font-weight-bold me-3">
          <span class="red--text">{{ vCustomer?.tag_type }}</span>
        </div> -->
        <div class="text-h4 font-weight-bold me-3">
          <v-chip
            v-if="dbCosting.tag_type"
            label
            style="font-size: 19px !important"
            class="text-capitalize"
            text-color="white"
            :color="dbCosting.tag_type == 'overseas' ? 'blue' : 'red'"
          >
            {{ dbCosting.tag_type }}
          </v-chip>
          Selling Currency:
          <span class="red--text">{{ dbCosting.selling_currency }}</span>
        </div>
      </div>
    </template>
    <template v-slot:header-action>
      <div>
        <v-btn
          :disabled="formLoading"
          v-on:click="goBack()"
          class="custom-bold-button custom-grey-border"
          depressed
          >Cancel</v-btn
        >

        <v-btn
          class="custom-bold-button ml-4"
          depressed
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          v-on:click="validateJob()"
          color="cyan white--text"
          >Save
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <div class="bg-white px-2">
          <v-form
            ref="createQuotationForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="validateJob()"
          >
            <v-row>
              <v-col md="7">
                <label for="costing-title" class="required"
                  >Costing Title</label
                >
                <TextValidateInput
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :rules="[
                    validateRules.required(dbCosting.title, 'Costing title'),
                    validateRules.maxLength(
                      dbCosting.title,
                      'Costing title',
                      50
                    ),
                  ]"
                  id="display-name"
                  placeholder="title"
                  counter="50"
                  :validationField="{
                    url_type: 'costing',
                    filter_type: 'costing',
                    field: 'title',
                  }"
                  :parent-id="dbCosting.id"
                  :current-id="dbCosting.id"
                  show-dropdown
                  v-model="dbCosting.title"
                  hideTopMargin
                ></TextValidateInput>

                <div class="pt-2">
                  <label for="opportunity">Opportunity</label>
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    id="opportunity"
                    :items="opportunityType"
                    :disabled="formLoading"
                    :loading="formLoading"
                    v-model="dbCosting.opportunity"
                    placeholder="Select Opportunity"
                    solo
                    flat
                    item-color="cyan"
                    item-text="name"
                    item-value="id"
                    hide-details
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="'No Opportunity Found.'"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mt-2"></v-divider>
                      <v-btn
                        class="mx-3"
                        depressed
                        v-on:click="addOpportunityDialogs()"
                        ><v-icon color="blue">mdi-plus</v-icon
                        ><span class="blue--text"> Add New</span>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </div>

                <div class="pt-2">
                  <label for="Project">Project</label>
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    id="Project"
                    :items="projectList"
                    :disabled="formLoading"
                    :loading="formLoading"
                    v-model="dbCosting.project"
                    placeholder="Select Project"
                    solo
                    flat
                    item-color="cyan"
                    item-text="name"
                    item-value="id"
                    hide-details
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="'No Project Found.'"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </div>

                <v-row>
                  <v-col md="6">
                    <v-layout class="align-center">
                      <v-flex md3
                        ><label class="my-0">Billing Location</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          v-on:click="openDialog('billing')"
                          small
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBilling?.id">
                      <span v-if="vBilling && vBilling.property_address">
                        <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                      >
                      <template>
                        <div
                          class="custom-flex-d"
                          v-if="vBilling.property_name"
                        >
                          <b>
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-home-map-marker
                            </v-icon>
                          </b>
                          <span>{{ vBilling.property_name }}<br /></span>
                        </div>
                        <div v-else>
                          <em class="text-muted">
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-home-map-marker
                            </v-icon>
                            no location Name</em
                          >
                        </div>
                      </template>

                      <template>
                        <div class="custom-flex-d">
                          <b>
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-map-marker
                            </v-icon>
                          </b>
                          <span>
                            <template v-if="vBilling.unit_no"
                              >{{ vBilling.unit_no }},</template
                            >
                            <template v-if="vBilling.street_1"
                              >{{ vBilling.street_1 }},</template
                            >
                            {{ vBilling.street_2 }}
                            <br />
                            {{ vBilling.country }}
                            <template
                              v-if="
                                vBilling.zip_code &&
                                vBilling.zip_code != '000000'
                              "
                            >
                              {{ vBilling.zip_code }}
                            </template>
                          </span>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select billing location
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select company</p>
                    </template>
                  </v-col>
                  <v-col md="6">
                    <v-layout class="mt-3 align-center">
                      <v-flex md3
                        ><label class="my-0">Contact Details</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('billing-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBillingContact?.id">
                      <p class="mb-0">{{ vBillingContact.full_name }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select company</p>
                    </template>
                    <v-layout v-if="false" class="mt-3">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="dbCosting.notify_billing_contact_person"
                          class="m-0 p-0"
                          color="cyan"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="5">
                <table width="100%" style="table-layout: fixed">
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label>Rate opportunity</label>
                    </td>
                    <td class="font-weight-700">
                      <v-rating
                        :readonly="formLoading"
                        v-model.trim="dbCosting.rating"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="your-reference-no" class="required"
                        >Reports</label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="costing-currency"
                        :items="reportListType"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbCosting.report"
                        :rules="[
                          validateRules.required(dbCosting.report, 'Report'),
                        ]"
                        placeholder="Select Report"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Report Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="reference-no"
                        ><!-- C/O or Customer Ref/Your Ref on PDF -->
                        Customer Reference # (For PDF as Your Ref.)</label
                      >
                    </td>
                    <td>
                      <v-text-field
                        id="reference-no"
                        v-model.trim="dbCosting.reference"
                        dense
                        filled
                        :disabled="formLoading"
                        :loading="formLoading"
                        color="cyan"
                        placeholder="Customer Reference #"
                        :rules="[
                          validateRules.minLength(
                            dbCosting.reference,
                            'Customer Reference',
                            1
                          ),
                          validateRules.maxLength(
                            dbCosting.reference,
                            'Customer Reference',
                            100
                          ),
                        ]"
                        solo
                        flat
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="your-reference-no">Selling Currency </label>
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="costing-currency"
                        :items="currencyListType"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbCosting.selling_currency"
                        placeholder="Select Currency"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Costing Currency Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label for="your-reference-no"
                        >Internal Reference #</label
                      >
                    </td>
                    <td>
                      <v-text-field
                        id="your-reference-no"
                        v-model.trim="dbCosting.your_reference"
                        dense
                        filled
                        :disabled="formLoading"
                        :loading="formLoading"
                        color="cyan"
                        placeholder="Internal Reference #"
                        :rules="[
                          validateRules.minLength(
                            dbCosting.your_reference,
                            'Internal Reference',
                            1
                          ),
                          validateRules.maxLength(
                            dbCosting.your_reference,
                            'Internal Reference',
                            100
                          ),
                        ]"
                        solo
                        flat
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="sales-representatives"
                        >Sales representatives</label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="sales-representatives"
                        :items="userList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbCosting.sales_executive"
                        placeholder="Sales representatives"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Sales Representative Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="prepared-by" class="required"
                        >Prepared by</label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="prepared-by"
                        :items="userList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        :rules="[
                          validateRules.required(
                            dbCosting.prepared_by_id,
                            'Prepared by'
                          ),
                        ]"
                        v-model.trim="dbCosting.prepared_by_id"
                        placeholder="Prepared by"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="prepared-by" class="required"
                        >Approver
                      </label>
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="approver-by"
                        :items="approverList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        :rules="[
                          validateRules.required(
                            dbCosting.approved_by_id,
                            'Approver'
                          ),
                        ]"
                        v-model.trim="dbCosting.approved_by_id"
                        placeholder="Approver"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="prepared-by" class="required"
                        >Validity
                      </label>
                    </td>
                    <td>
                      <!-- v-on:click:append-outer="manageValidityDialog = true"
                      append-outer-icon="mdi-cog" -->
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="prepared-by"
                        :items="validPeriod"
                        :rules="[
                          validateRules.required(
                            dbCosting.validity_till,
                            'Validity'
                          ),
                        ]"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbCosting.validity_till"
                        placeholder="validity"
                        solo
                        flat
                        item-color="cyan"
                        item-text="text"
                        item-value="value"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                      <template>
                        <ManageValidatedepartment
                          :dialog="manageValidityDialog"
                          :e_costing="validPeriod"
                          v-on:close-dialog="manageValidityDialog = false"
                          v-on:get-e-designation="setOptions"
                        ></ManageValidatedepartment>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td width="150" class="font-weight-600">
                      <label for="tags">Tags </label>
                    </td>
                    <td>
                      <tag-auto-complete
                        v-if="false"
                        type="costing"
                        v-model="dbCosting.tags"
                      />
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td>
                      <label for="type">Type</label>
                    </td>
                    <td>
                      <div class="d-flex ml-1">
                        <v-btn
                          depressed
                          tile
                          :outlined="!(dbCosting.type == 1)"
                          v-on:click="
                            dbCosting.type = 1 /*  changeContractStatus() */
                          "
                          value="1"
                          color="light-green darken-3 white--text"
                          >New</v-btn
                        >
                        <v-btn
                          class="mx-2"
                          depressed
                          tile
                          :outlined="!(dbCosting.type == 2)"
                          v-on:click="
                            dbCosting.type = 2 /*  changeContractStatus() */
                          "
                          value="2"
                          color="orange darken-4 white--text"
                          >Maintenance</v-btn
                        >
                      </div>
                    </td>
                  </tr>
                  <template v-if="!$route?.query?.edit">
                    <tr v-if="false">
                      <td width="150" class="font-weight-600">
                        <label>Is Contract ?</label>
                      </td>
                      <td valign="middle">
                        <v-autocomplete
                          hide-details
                          v-on:change="enableContract($event)"
                          v-model="dbCosting.is_contract"
                          :items="contractType"
                          dense
                          flat
                          filled
                          item-color="cyan"
                          color="cyan"
                          solo
                          item-value="value"
                          item-text="text"
                        >
                        </v-autocomplete>
                      </td>
                    </tr>
                  </template>
                </table>
              </v-col>
              <v-col
                cols="12"
                v-if="dbCosting.type == 2 && !$route?.query?.edit && false"
                ref="qtContract"
              >
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue d-flex align-center"
                    >
                      Contract
                      <v-checkbox
                        v-if="false"
                        color="cyan"
                        hide-details
                        class="ml-5 py-0 my-0"
                        :value="contract_status"
                        v-on:change="changeContractStatus()"
                      />
                    </h3>
                  </v-card-title>
                  <v-card-text v-if="contract_status" class="p-6 font-size-16">
                    <div>
                      <recurring-schedule
                        :key="`contract-recurring-schedule-${schedule_key}`"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" ref="qtLineItem" class="">
                <v-container fluid class="pa-0">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_line_items = !show_line_items"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_line_items
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        Line Items
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        v-show="show_line_items"
                        ref="lineItem"
                        class="font-size-16 py-0"
                      >
                        <line-item
                          v-if="related_type > 0"
                          :related-type="related_type"
                          is-job
                          :db-line-items="dbLineItems"
                          :db-equipments="dbEquipments"
                          is-costing
                        />
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>

              <v-col cols="12" ref="qtNote">
                <v-container fluid class="px-0">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_notes_attachment = !show_notes_attachment"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_notes_attachment
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        INTERNAL NOTES
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        class="p-6 font-size-16"
                        v-show="show_notes_attachment"
                      >
                        <v-layout>
                          <v-flex class="mr-4" md12>
                            <label class="font-size-16 font-weight-600"
                              >Internal Note</label
                            >

                            <tiny-mce
                              :disabled="formLoading"
                              v-model="dbCosting.admin_note"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout class="mt-4">
                          <v-flex md12 class="mr-4" v-if="false">
                            <label class="font-size-16 font-weight-600"
                              >Attachments</label
                            >
                            <file-upload
                              v-model="dbCosting.attachments"
                              :maxLimit="5"
                            >
                            </file-upload>
                          </v-flex>
                          <v-flex md12>
                            <DocumnetList
                              v-model="dbCosting.attachments"
                              :document-type="2"
                            >
                            </DocumnetList>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>

              <v-col cols="12" ref="qtNote">
                <v-container fluid class="px-0">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="
                        show_client_notes_attachment =
                          !show_client_notes_attachment
                      "
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_client_notes_attachment
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        PAYMENT TERMS
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        class="p-6 font-size-16"
                        v-show="show_client_notes_attachment"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          label="Payment Terms"
                          solo
                          flat
                          class="mb-2"
                          item-color="cyan"
                          item-text="label"
                          item-value="id"
                          hide-details
                          :disabled="formLoading"
                          :loading="formLoading"
                          :items="paymentTermsList"
                          v-model="dbCosting.payment_term_id"
                          v-on:change="update_payment_term_condition()"
                        />

                        <tiny-mce
                          :disabled="formLoading"
                          v-model="dbCosting.payment_terms"
                        />
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12" class="pb-15" ref="qtTermsConditions">
                <v-container fluid class="px-0">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_terms_condition = !show_terms_condition"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_terms_condition
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        TERMS &amp; CONDITIONS
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        class="p-6 font-size-16"
                        v-show="show_terms_condition"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          label="Terms &amp; Conditions"
                          solo
                          flat
                          class="mb-2"
                          item-color="cyan"
                          item-text="label"
                          item-value="id"
                          hide-details
                          :disabled="formLoading"
                          :loading="formLoading"
                          :items="termsConditionList"
                          v-model="dbCosting.term_condition_id"
                          v-on:change="update_term_condition()"
                        />
                        <tiny-mce
                          :disabled="formLoading"
                          v-model="dbCosting.term_conditions"
                        />
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-form>

          <customer-select
            :dialog="reCustomerDialog"
            @close="closeCustomerDialog"
          />
          <customer-create :key="`quotation-customer-create-${dialog_key}`" />
          <person-select
            :dialog="contactDialog"
            v-on:close="contactDialog = false"
            :type="contactType"
          />
          <address-select
            :dialog="propertyDialog"
            v-on:close="propertyDialog = false"
            :type="propertyType"
            :label="propertyLabel"
          />
          <AddOppDialog
            v-if="addOpportunityDialog"
            :dialog="addOpportunityDialog"
            :key="`opportunity-create-${dialog_key}`"
            v-on:close="addOpportunityDialog = false"
            v-on:success="getOpportunity()"
          />
          <Dialog :common-dialog="contractDialog" :dialog-width="680">
            <template v-slot:title> Contract Detail </template>
            <template v-slot:body>
              <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
                <v-container fluid>
                  <v-row>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Duration</label
                      >
                      <v-text-field
                        dense
                        filled
                        id="duration-value"
                        color="cyan"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-mask="'##'"
                        v-model.trim="dbCosting.duration_value"
                        label="Duration"
                        solo
                        flat
                        hide-details
                        v-on:keyup="updateDuration()"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-type" class="font-weight-600"
                        >Duration Type</label
                      >
                      <v-select
                        dense
                        color="cyan"
                        filled
                        id="duration-type"
                        :items="durationTypeList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbCosting.duration_type"
                        v-on:change="updateDuration()"
                        label="Duration"
                        solo
                        flat
                        item-color="cyan"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Duration Type Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Contract Start Date</label
                      >
                      <date-picker
                        solo
                        flat
                        :default-date="dbCosting.contract_start"
                        v-model="dbCosting.contract_start"
                      ></date-picker>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Contract End Date</label
                      >
                      <date-picker
                        solo
                        flat
                        :default-date="dbCosting.contract_end"
                        v-model="dbCosting.contract_end"
                      ></date-picker>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Base</label
                      >
                      <v-select
                        dense
                        color="cyan"
                        filled
                        id="duration-type"
                        :items="baseList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbCosting.base_type"
                        label="Base"
                        solo
                        flat
                        item-color="cyan"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Duration Type Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Base No.</label
                      >
                      <v-text-field
                        dense
                        filled
                        id="no-of-services"
                        color="cyan"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-mask="'####'"
                        v-model.trim="dbCosting.total_service"
                        label="Base No."
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
            <template v-slot:action>
              <v-btn
                v-on:click="contractDialog = false"
                class="mx-2 custom-grey-border custom-bold-button"
              >
                No! Close
              </v-btn>
              <v-btn
                v-on:click="saveData()"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
              >
                Yes! Continue
              </v-btn>
            </template>
          </Dialog>
        </div>
      </perfect-scrollbar>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import AddOppDialog from "@/view/components/AddOppDialog.vue";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import Dialog from "@/view/pages/partials/Dialog";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/components/QDatePicker.vue";
import LineItemNewV2 from "@/view/pages/partials/Line-Item-NewV2.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import {
  SET_DIALOG_STATUS,
  SET_CREATE_DIALOG_STATUS,
  CLEAR_CUSTOMER,
} from "@/core/services/store/customer.module";
import {
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
  RESET_CREATE_STATE,
} from "@/core/services/store/visit.module";
import {
  SET_MODELS,
  SET_PARTS,
  SET_UOM,
  SET_PRODUCTTYPE,
  SET_CURRENCY,
  SET_BRANDS,
  SET_EDIT_LINE_ITEM,
  SET_EDIT_CALCULATION,
  CLEAR_LINE_ITEM,
} from "@/core/services/store/line.item.v2.module";
import {
  UPLOAD,
  QUERY,
  POST,
  PATCH,
} from "@/core/services/store/request.module";
import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import KTCookie from "@/assets/js/components/cookie";
import DocumnetList from "@/view/pages/DocumnetList";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
// import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import { GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ManageValidatedepartment from "@/view/pages/partials/Manage-Validate.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-quotation",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      designations: [],
      schedule_key: Number(new Date()),
      module: "costing",
      costing: [],
      projectList: [],
      manageValidityDialog: false,
      pageLoading: false,
      dialog_key: Number(new Date()),
      addOpportunityDialog: false,
      formLoading: false,
      formValid: true,
      quotationCreated: false,
      show_line_items: false,
      show_notes_attachment: false,
      show_client_notes_attachment: false,
      show_terms_condition: false,
      related_type: 0,

      contractDialog: false,

      quotation_recurring_invoice: false,
      is_quotation_invoice_allow: false,
      opportunityType: [],
      projectType: [],
      durationTypeList: [
        { text: "Year", value: "year" },
        { text: "Month", value: "month" },
      ],
      baseList: [
        { text: "No. of Services", value: "service_no" },
        { text: "Hours", value: "hours" },
        { text: "None", value: "none" },
      ],
      paymentDueList: [
        { value: 2, text: "Net 15" },
        { value: 3, text: "Net 30" },
        { value: 4, text: "Net 45" },
      ],
      neededInvoiceList: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      invoiceTypes: [
        { value: "one-off", text: "One Off" },
        { value: "recurring", text: "Recurring" },
      ],
      contractType: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      /*   tagList: [
        {
          value: "local",
          text: "Local",
        },
        {
          value: "overseas",
          text: "Overseas",
        },
      ], */
      validPeriod: [
        /*   { value: 7, text: "7 Days" },
        { value: 15, text: "15 Days" },
        { value: 30, text: "30 Days" },
        { value: 60, text: "60 Days" }, */
      ],
      show_invoicing: false,
      dbCosting: {
        barcode: null,
        title: null,
        project: null,
        description: null,
        status: "draft",
        report: null,
        billing_contact_person: false,
        property_contact_person: false,
        rating: 1,
        type: 1,
        tags: [],
        /*   date: null, */
        prepared_by_id: null,
        sales_executive: null,
        selling_currency: "SGD",
        reference: null,
        open_till: null,
        validity_till: 30,
        /*   sale_by: null, */
        /*     customer_ref: null, */
        your_reference: null,
        /*   quote_by: null, */
        term_condition_id: 0,
        term_conditions: null,
        payment_term_id: 0,
        payment_terms: null,
        admin_note_id: 0,
        admin_note: null,
        /*  client_note_id: 0, */
        client_note: null,
        attachments: [
          {
            file: null,
            name: null,
            remark: null,
            suffix: null,
            tags: null,
            search: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ],
        is_contract: 0,
        duration_value: 1,
        duration_type: "year",
        contract_start: moment().format("YYYY-MM-DD"),
        contract_end: moment().add(1, "year").format("YYYY-MM-DD"),
        duration: null,
        total_service: null,
        base_type: "service_no",
        tag_type: null,
      },
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      adminNoteList: [],
      clientNoteList: [],
      termsConditionList: [],
      paymentTermsList: [],
      userList: [],
      approverList: [],
      currencyListType: ["SGD", "USD"],
      reportListType: ["Include", "exclude"],
      dbLineItems: [],
      dbEquipments: [],
      todayDate: null,
      updatedQuotationData: {},
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
      contract_status: false,
    };
  },
  components: {
    AddOppDialog,
    "customer-select": CustomerSelect,
    "customer-create": CustomerCreate,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    "line-item": LineItemNewV2,
    "date-picker": DatePicker,
    "tiny-mce": TinyMCE,
    "tag-auto-complete": TagAutoComplete,
    TextValidateInput,
    Dialog,
    CreateUpdateTemplate,
    DocumnetList,
    ManageValidatedepartment,
  },
  watch: {
    vCustomer: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param?.tag_type) {
          this.dbCosting.tag_type = param.tag_type;
        }
      },
    },
  },
  methods: {
    manageLimit(e, type) {
      if (
        this.dbCosting.title &&
        this.dbCosting.title.length > 49 &&
        type == "title"
      ) {
        e.preventDefault();
      }
    },
    setOptions() {
      let _this = this;
      _this.$store
        .dispatch(GET, {
          url: `setting/validity/${this.module}`,
        })
        .then(({ data }) => {
          _this.validPeriod = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getprojects() {
      this.loading = true;
      this.list = [];
      this.$store
        .dispatch(QUERY, {
          url: "project-list",
          data: { search: this.search },
        })
        .then((response) => {
          this.projectList = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getOpportunity() {
      this.$store
        .dispatch(QUERY, {
          url: `opportunity-list`,
        })
        .then((response) => {
          this.opportunityType = response.data;
        })
        .catch(() => {
          /*  this.goBack(); */
        })
        .finally(() => {
          //
        });
    },

    saveData() {
      this.contractDialog = false;
    },

    createCustomerDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    addOpportunityDialogs() {
      this.dialog_key = Number(new Date());
      this.addOpportunityDialog = true;
    },

    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    update_term_condition() {
      let output = this.lodash.find(this.termsConditionList, {
        id: this.dbCosting.term_condition_id,
      });
      if (output) {
        this.dbCosting.term_conditions = output.description;
      }
    },
    update_payment_term_condition() {
      let output = this.lodash.find(this.paymentTermsList, {
        id: this.dbCosting.payment_term_id,
      });
      if (output) {
        this.dbCosting.payment_terms = output.description;
      }
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.dbCosting.admin_note_id,
      });
      if (output) {
        this.dbCosting.admin_note = output.description;
      }
    },

    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please select company.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Service Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Service Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }
      // const allHeaders = this.vSelectedLineItem.every(
      //   (obj) => obj.type === "header"
      // );
     
      if (this.v2SelectedLineItem?.length == 0) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const groupNames = [];
      for (const item of this.v2SelectedLineItem) {
        if (!item.group_name || item.group_name.trim() === "") {
          ErrorEventBus.$emit("update:error", "Group name is required.");
          return false;
        }

        const nameLower = item.group_name.trim().toLowerCase();
        if (groupNames.includes(nameLower)) {
          ErrorEventBus.$emit("update:error", "Group name must be unique.");
          return false;
        }

        groupNames.push(nameLower);
      }

      const isValid = this.v2SelectedLineItem.every((item, index) => {
        const hasAtLeastOneBrandProduct = item.children.some((child) => {
          return child.header_item.some((header) => header.product === "Brand");
        });

        if (!hasAtLeastOneBrandProduct) {
          let indexNew = index + 1;
          ErrorEventBus.$emit(
            "update:error",
            `At least one product type must be Brand for Row: ${indexNew}`
          );
          return false;
        }

        return true;
      });

      if (!isValid) {
        return false;
      }

      const validateStatus = this.$refs.createQuotationForm.validate();

      const formErrors = this.validateForm(this.$refs.createQuotationForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      const new_attachments = await this.uploadFiles();

      const request = {
        ...this.dbCosting,
        attachments: [...this.dbCosting.attachments, ...new_attachments],
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        calculation: this.v2Calculation,
        duplicate: this.$route?.query?.duplicate ?? 0,
        revise: this.$route?.query?.revise ?? 0,
        "line-item": this.v2SelectedLineItem,
      };

      let requestType = POST;
      let requestURL = "costing-v1";

      let costingId = this.$route?.query?.edit ?? 0;

      if (costingId) {
        requestType = PATCH;
        requestURL = `costing-v1/${costingId}`;
      }

      this.formLoading = true;

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(({ data }) => {
          this.quotationCreated = true;
          this.$store.dispatch(CLEAR_CUSTOMER);
          this.$store.dispatch(CLEAR_LINE_ITEM);
          this.$nextTick(() => {
            this.$router.push(
              this.getDefaultRoute("costing.detail", {
                params: { id: data?.id },
              })
            );
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },
    get_attributes() {
      this.$store
        .dispatch(QUERY, {
          url: "costing/options",
          data: {
            costing: ObjectPath.get(this.$route, "query.edit"),
            duplicate: ObjectPath.get(this.$route, "query.duplicate"),
            revise: ObjectPath.get(this.$route, "query.revise"),
            opportunity: ObjectPath.get(this.$route, "query.opportunity"),
            requote: ObjectPath.get(this.$route, "query.requote"),
            enquiry: ObjectPath.get(this.$route, "query.enquiry"),
            visit: ObjectPath.get(this.$route, "query.visit"),
          },
        })
        .then((response) => {
          this.dbCosting.barcode = ObjectPath.get(response, "data.barcode");
          this.validPeriod = ObjectPath.get(response, "data.validity_types");
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Costing", route: "costing" },
            { title: "Create" },
            { title: this.dbCosting.barcode },
          ]);
          this.$store.commit(SET_BRANDS, response?.data?.brandList);
          this.$store.commit(SET_CURRENCY, response?.data?.currencyListType);
          this.$store.commit(SET_PRODUCTTYPE, response?.data?.productType);
          this.$store.commit(SET_UOM, response?.data?.uomListType);
          this.$store.commit(SET_PARTS, response?.data?.partList);
          this.$store.commit(SET_MODELS, response?.data?.modelList);
          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list",
            []
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list",
            []
          );
          this.termsConditionList = ObjectPath.get(
            response,
            "data.term_condition_list",
            []
          );
          this.paymentTermsList = ObjectPath.get(
            response,
            "data.payment_term_list",
            []
          );

          let defaultTermConditionObject = this.lodash.find(
            this.termsConditionList,
            {
              primary: 1,
            }
          );
          if (defaultTermConditionObject) {
            this.dbCosting.term_condition_id =
              defaultTermConditionObject?.id ?? 0;
            this.dbCosting.term_conditions =
              defaultTermConditionObject?.description;
          }

          let defaultPaymentTermObject = this.lodash.find(
            this.paymentTermsList,
            {
              primary: 1,
            }
          );
          if (defaultPaymentTermObject) {
            this.dbCosting.payment_term_id =
              defaultTermConditionObject?.id ?? 0;
            this.dbCosting.payment_terms =
              defaultTermConditionObject?.description;
          }
          this.dbCosting.term_conditions =
            response?.data?.costing?.term_conditions;
          this.dbCosting.payment_terms = response?.data?.costing?.payment_terms;

          let defaultClientObject = this.lodash.find(this.clientNoteList, {
            primary: 1,
          });
          if (defaultClientObject) {
            this.dbCosting.client_note_id = defaultClientObject?.id ?? 0;
            this.dbCosting.client_note = defaultClientObject?.description;
          }

          let defaultAdminObject = this.lodash.find(this.adminNoteList, {
            primary: 1,
          });
          if (defaultAdminObject) {
            this.dbCosting.admin_note_id = defaultAdminObject?.id ?? 0;
            this.dbCosting.admin_note = defaultAdminObject?.description;
          }

          this.userList = ObjectPath.get(response, "data.sales_users", []);
          this.approverList = ObjectPath.get(response, "data.approvers", []);
          if (this.$route?.query?.edit > 0) {
            this.dbCosting.barcode = response?.data?.costing?.barcode;
            this.dbCosting.opportunity = response?.data?.costing?.opportunity;
            this.dbCosting.project = response?.data?.costing?.project;
            this.dbCosting.title = response?.data?.costing?.title;
            this.dbCosting.rating = response?.data?.costing?.rating ?? 1;
            this.dbCosting.report = response?.data?.costing?.report;
            this.dbCosting.reference = response?.data?.costing?.reference;
            this.dbCosting.sales_executive =
              response?.data?.costing?.sales_executive;
            this.dbCosting.prepared_by_id =
              response?.data?.costing?.prepared_by_id;
            this.dbCosting.approved_by_id =
              response?.data?.costing?.approved_by_id;
            this.dbCosting.validity_till =
              response?.data?.costing?.validity_till;
            this.dbCosting.tags = response?.data?.costing?.tags;
            // this.dbCosting.term_conditions = response?.data?.costing?.term_conditions;
            this.dbCosting.term_condition_id =
              response?.data?.costing?.term_condition_id;
            this.dbCosting.payment_term_id =
              response?.data?.costing?.payment_term_id;

            this.dbCosting.admin_note = response?.data?.costing?.admin_note;
            this.dbCosting.selling_currency =
              response?.data?.costing?.selling_currency;
            this.dbCosting.tag_type = response?.data?.costing?.tag_type;
            this.$store.commit(SET_CUSTOMER, response?.data?.costing?.customer);
            this.$store.commit(SET_BILLING, response?.data?.costing?.billing);
            this.$store.commit(SET_PROPERTY, response?.data?.costing?.property);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              response?.data?.costing?.property_contact_person
            );
            this.$store.commit(
              SET_BILLING_CONTACT,
              response?.data?.costing?.billing_contact_person
            );

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Costing", route: "costing" },
              { title: "update" },
              { title: response?.data?.costing?.barcode },
            ]);
            this.$store.dispatch(
              SET_EDIT_LINE_ITEM,
              response?.data?.costing?.items ?? []
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              total_selling: response?.data?.costing?.total_selling,
              total_cost_value: response?.data?.costing?.total_cost_value,
              total_margin: response?.data?.costing?.total_margin,
              discount_value: response?.data?.costing?.discount_value,
              discount_type: response?.data?.costing?.discount_type,
              main_discount: response?.data?.costing?.main_discount,
              lump_discount_value: response?.data?.costing?.lump_discount_value,
              lump_discount_type: response?.data?.costing?.lump_discount_type,
              lump_sum_discount: response?.data?.costing?.lump_sum_discount,
              discounted_total_selling:
                response?.data?.costing?.discounted_total_selling,
              grand_total: response?.data?.costing?.grand_total,
            });
            this.dbCosting.sale_by = ObjectPath.get(
              response,
              "data.user_id",
              0
            );
            this.dbCosting.quote_by = ObjectPath.get(
              response,
              "data.user_id",
              0
            );
          }
          if (!this.$route?.query?.edit) {
            this.dbCosting.prepared_by_id = ObjectPath.get(
              response,
              "data.user_id",
              0
            );
          }
          if (this.$route?.query?.duplicate > 0) {
            /* this.dbCosting.barcode = response?.data?.costing?.barcode; */
            this.dbCosting.opportunity = response?.data?.duplicate?.opportunity;
            this.dbCosting.project = response?.data?.duplicate?.project;
            this.dbCosting.title = response?.data?.duplicate?.title;
            this.dbCosting.rating = response?.data?.duplicate?.rating ?? 1;
            this.dbCosting.report = response?.data?.duplicate?.report;
            this.dbCosting.reference = response?.data?.duplicate?.reference;
            this.dbCosting.sales_executive =
              response?.data?.duplicate?.sales_executive;
            this.dbCosting.prepared_by_id =
              response?.data?.duplicate?.prepared_by_id;
            this.dbCosting.approved_by_id =
              response?.data?.duplicate?.approved_by_id;
            this.dbCosting.validity_till =
              response?.data?.duplicate?.validity_till;
            this.dbCosting.tags = response?.data?.duplicate?.tags;
            // this.dbCosting.term_conditions = response?.data?.duplicate?.term_conditions;
            this.dbCosting.term_condition_id =
              response?.data?.duplicate?.term_condition_id;
            this.dbCosting.payment_term_id =
              response?.data?.duplicate?.payment_term_id;

            this.dbCosting.admin_note = response?.data?.duplicate?.admin_note;
            this.dbCosting.selling_currency =
              response?.data?.duplicate?.selling_currency;
            this.dbCosting.tag_type = response?.data?.duplicate?.tag_type;

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Costing", route: "costing" },
              { title: "Duplicate" },
              { title: this.dbCosting?.barcode },
            ]);
            this.$store.dispatch(
              SET_EDIT_LINE_ITEM,
              response?.data?.duplicate?.items ?? []
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              total_selling: response?.data?.duplicate?.total_selling,
              total_cost_value: response?.data?.duplicate?.total_cost_value,
              total_margin: response?.data?.duplicate?.total_margin,
              discount_value: response?.data?.duplicate?.discount_value,
              discount_type: response?.data?.duplicate?.discount_type,
              main_discount: response?.data?.duplicate?.main_discount,
              lump_discount_value:
                response?.data?.duplicate?.lump_discount_value,
              lump_discount_type: response?.data?.duplicate?.lump_discount_type,
              lump_sum_discount: response?.data?.duplicate?.lump_sum_discount,
              discounted_total_selling:
                response?.data?.duplicate?.discounted_total_selling,
              grand_total: response?.data?.duplicate?.grand_total,
            });
            this.dbCosting.sale_by = ObjectPath.get(
              response,
              "data.user_id",
              0
            );
            this.dbCosting.quote_by = ObjectPath.get(
              response,
              "data.user_id",
              0
            );
          }
          if (this.$route?.query?.revise > 0) {
            /* this.dbCosting.barcode = response?.data?.costing?.barcode; */
            this.dbCosting.opportunity = response?.data?.revise?.opportunity;
            this.dbCosting.project = response?.data?.revise?.project;
            this.dbCosting.title = response?.data?.revise?.title;
            this.dbCosting.rating = response?.data?.revise?.rating ?? 1;
            this.dbCosting.report = response?.data?.revise?.report;
            this.dbCosting.reference = response?.data?.revise?.reference;
            this.dbCosting.sales_executive =
              response?.data?.revise?.sales_executive;
            this.dbCosting.prepared_by_id =
              response?.data?.revise?.prepared_by_id;
            this.dbCosting.approved_by_id =
              response?.data?.revise?.approved_by_id;
            this.dbCosting.validity_till =
              response?.data?.revise?.validity_till;
            this.dbCosting.tags = response?.data?.revise?.tags;
            // this.dbCosting.term_conditions = response?.data?.revise?.term_conditions;
            this.dbCosting.term_condition_id =
              response?.data?.revise?.term_condition_id;
            this.dbCosting.payment_term_id =
              response?.data?.revise?.payment_term_id;

            this.dbCosting.admin_note = response?.data?.revise?.admin_note;
            this.dbCosting.selling_currency =
              response?.data?.revise?.selling_currency;
            this.dbCosting.tag_type = response?.data?.revise?.tag_type;
            this.$store.commit(SET_CUSTOMER, response?.data?.revise?.customer);
            this.$store.commit(SET_BILLING, response?.data?.revise?.billing);
            this.$store.commit(SET_PROPERTY, response?.data?.revise?.property);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              response?.data?.revise?.property_contact_person
            );
            this.$store.commit(
              SET_BILLING_CONTACT,
              response?.data?.revise?.billing_contact_person
            );
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Costing", route: "costing" },
              { title: "Revise" },
              { title: response?.data?.revise?.barcode },
            ]);
            this.$store.dispatch(
              SET_EDIT_LINE_ITEM,
              response?.data?.revise?.items ?? []
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              total_selling: response?.data?.revise?.total_selling,
              total_cost_value: response?.data?.revise?.total_cost_value,
              total_margin: response?.data?.revise?.total_margin,
              discount_value: response?.data?.revise?.discount_value,
              discount_type: response?.data?.revise?.discount_type,
              main_discount: response?.data?.revise?.main_discount,
              lump_discount_value: response?.data?.revise?.lump_discount_value,
              lump_discount_type: response?.data?.revise?.lump_discount_type,
              lump_sum_discount: response?.data?.revise?.lump_sum_discount,
              discounted_total_selling:
                response?.data?.revise?.discounted_total_selling,
              grand_total: response?.data?.revise?.grand_total,
            });
            this.dbCosting.sale_by = ObjectPath.get(
              response,
              "data.user_id",
              0
            );
            this.dbCosting.quote_by = ObjectPath.get(
              response,
              "data.user_id",
              0
            );
          }
          if (this.$route?.query?.opportunity > 0) {
            /* this.dbCosting.barcode = response?.data?.costing?.barcode; */
            this.dbCosting.opportunity = response?.data?.opportunity?.id;
            this.dbCosting.project = this.lodash.toSafeInteger(
              response?.data?.opportunity?.project_id
            );
            console.log(response?.data?.opportunity?.customer);
            this.dbCosting.title =
              response?.data?.opportunity?.opportunity_name;
            this.dbCosting.reference = response?.data?.opportunity?.reference;
            this.dbCosting.title =
              response?.data?.opportunity?.customer?.tag_type;
            this.$store.commit(
              SET_CUSTOMER,
              response?.data?.opportunity?.customer
            );
            this.$store.commit(
              SET_BILLING,
              response?.data?.opportunity?.billing
            );
            this.$store.commit(
              SET_PROPERTY,
              response?.data?.opportunity?.property
            );
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              response?.data?.opportunity?.property_person
            );
            this.$store.commit(
              SET_BILLING_CONTACT,
              response?.data?.opportunity?.billing_person
            );
          }
        })
        .catch((error) => {
          this.logError(error);
          /*   this.goBack(); */
        })
        .finally(() => {
          // this.formLoading = false;
        });
    },
    getCustomer(customerId, contactPersonId, propertyId) {
      this.$store
        .dispatch(QUERY, {
          url: `customer-with-relation/${customerId}`,
          data: {
            contact_person: contactPersonId,
            property: propertyId,
          },
        })
        .then((response) => {
          this.$store.commit(
            SET_CUSTOMER,
            ObjectPath.get(response, "data.customer", {})
          );
          this.$store.commit(
            SET_PROPERTY,
            ObjectPath.get(response, "data.property", {})
          );
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            ObjectPath.get(response, "data.property_contact_person", {})
          );
          this.$store.commit(
            SET_BILLING,
            ObjectPath.get(response, "data.billing", {})
          );
          this.$store.commit(
            SET_BILLING_CONTACT,
            ObjectPath.get(response, "data.billing_contact_person", {})
          );
        })
        .catch(() => {
          /*   this.goBack(); */
        })
        .finally(() => {
          //
        });
    },
  },
  mounted() {
    this.$refs.createQuotationForm.validate();
    /*     this.setOptions(); */
    this.getOpportunity();
    this.getprojects();
    this.get_attributes();
    const customerId = Number(this.$route?.query?.customer ?? 0);
    const contactPersonId = Number(this.$route?.query?.contact_person ?? 0);
    const propertyId = Number(this.$route?.query?.property ?? 0);

    if (customerId) {
      this.getCustomer(customerId, contactPersonId, propertyId);
    }
    if (ObjectPath.get(this.$route, "query.enquiry") > 0) {
      this.related_type = 6;
      this.tag_type = "enquiry";
    } else if (ObjectPath.get(this.$route, "query.visit") > 0) {
      this.related_type = 3;
      this.tag_type = "visit";
    } else {
      this.related_type = 1;
      this.tag_type = "quotation";
    }
  },
  destroyed() {
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    /*    this.$store.dispatch(CLEAR_INVOICE); */
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbCosting.date = this.todayDate;
    this.dbCosting.invoice_date = this.todayDate;
    this.dbCosting.enquiry = this.lodash.toSafeInteger(
      ObjectPath.get(this.$route, "query.enquiry")
    );
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  computed: {
    ...mapGetters([
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "reCustomerDialog",
      "v2SelectedLineItem",
      "v2Calculation",
    ]),
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.quotationCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
